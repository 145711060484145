const INFO = {
	main: {
		title: "Sotonye portfolio",
		name: "Sotonye McLeod Bob-Manuel.",
		email: "hi@sotonye.com",
		logo: "../SB.png",
	},

	socials: {
		twitter: "https://x.com/sotonye_x",
		github: "https://github.com/sotonye-m",
		linkedin: "https://linkedin.com/in/sotonye",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Software Engineer, with a focus on Frontend Development.",
		description:
			"Software Engineer with a focus on creating aesthetically pleasing yet functional user interfaces, to help businesses reach new users and retain them.",
	},

	about: {
		title: "I’m Sotonye McLeod Bob-Manuel.",
		description:
			"Software engineer with extensive experience, consistently delivering exceptional results. As a frontend engineering leader, I specialize in utilizing technologies such as React, JavaScript, Vue.js, Nuxt.js, PHP, and REST APIs. My proven track record in project management involves collaborating with cross-functional teams to ensure seamless user experiences. I am adept at applying Agile methodologies to streamline development processes and improve project efficiency. My experience extends to product conceptualization, building, and optimizing innovative web applications. As an Information Technology Consultant, I provide strategic advice on technology adoption, operations optimization, and productivity enhancement. ",
	},

	articles: {
		title: "My thoughts",
		description:
			"Collection of my thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Jamitan",
			description:
				"Developer - Website, Blog for Jamitan a GIS Technology company.",
			logo: "https://www.cdnlogo.com/logos/r/63/react.svg",
			logo2:"https://www.cdnlogo.com/logos/p/71/php.svg",
			linkText: "View Project",
			link: "https://jamitan.com",
		},

		{
			title: "Zeenah Landing Page",
			description:
				"Developer - Zeenah , Financial Technology company",
			logo: "https://www.cdnlogo.com/logos/n/43/nuxt-square.svg",
			linkText: "View Project",
			link: "https://zeenah.app",
		},

		{
			title: "ZeenahPay",
			description:
				"Developer - ZeenahPay, Fintech Web App",
			logo: "https://www.cdnlogo.com/logos/r/63/react.svg",
			linkText: "View Project",
			link: "https://Zeenahpay.com",
		},

		{
			title: "Greg Daniel Clothing",
			description:
				"Developer - Online fashion Store for Greg Daniel Fashion house",
			logo: "https://www.cdnlogo.com/logos/r/63/react.svg",
			linkText: "View Project",
			link: "https://gregdanielclothings.com",
		},

		{
			title: "Uzu Ticket",
			description:
				"Contributor - helping build a ticket management service",
			logo: "https://www.cdnlogo.com/logos/v/69/vue.svg",
			linkText: "View Project",
			link: "https://uzuticket.com",
		},
	],
};

export default INFO;
